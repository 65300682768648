/* eslint-disable no-mixed-operators */
import React, { useEffect, useState } from "react";
import {
  ENROLLEDCOURSE_URL,
  EVENT_URL,
  EVENTREGISTRATION_URL,
  HOMECOURSELEVEL_URL,
  HOMECOURSELEVELBYCOURSE_URL,
  HOMECOURSESCHEDULE_URL,
  ORDER_URL,
} from "../../../../utils/endpoint";
import {
  LOCALSTORAGE_USERDETAIL,
  LOCALSTORAGE_TOKEN,
} from "../../../../utils/Types";
import Label from "components/Label/Label";
import axios from "axios";
import { Alert } from "reactstrap";
import ButtonPrimary from "components/Button/IPIButtonPrimary";
import { Link, useHistory, useParams } from "react-router-dom";
import moment from "moment";
import Input from "components/Input/IPIInput";
import Select from "react-select";
import ButtonSecondary from "components/Button/ButtonSecondary";

export const Sidebar = () => {
  const params: any = useParams();
  const { id } = params;

  const history: any = useHistory();

  const [levels, setLevels]: any = useState([]);
  const [schedules, setSchedules]: any = useState([]);

  const [levelText, setLevelText]: any = useState(null);
  const [scheduleText, setScheduleText]: any = useState(null);

  const [isSelectLevel, setIsSelectLevel]: any = useState(false);
  const [isSelectSchedule, setIsSelectSchedule]: any = useState(false);
  const [isSelectDomisili, setIsSelectDomisili]: any = useState(false);
  const [isSelectSertifikat, setIsSelectSertifikat]: any = useState(false);

  const [isCertificate, setIsCertificate]: any = useState(false);
  const [isHideSubmit, setIsHideSubmit]: any = useState(false);
  const [isLoading, setIsLoading]: any = useState(false);

  const [linkWAG, setLinkWAG]: any = useState(null);

  const [status, setStatus]: any = useState("");

  const [loading, setLoading] = useState(false);
  const [isAlertOn, setAlert] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const errorHandling = (msg: any) => {
    setErrorMsg(` ${JSON.stringify(msg)}`);
    setLoading(false);
    setAlert(true);
    setTimeout(() => {
      setAlert(false);
    }, 10000);
  };

  const localdata: any = JSON.parse(
    localStorage.getItem(LOCALSTORAGE_USERDETAIL) || "null"
  );
  useEffect(() => {
    // const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    // const options = {
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: `Token ${token}`,
    //   },
    // };
    axios
      .get(`${HOMECOURSELEVELBYCOURSE_URL}${params.id}/`)
      .then((res: any) => {
        setLevels(
          res.data.map((item: any) => ({
            value: item.id,
            label: item.level,
          }))
        );
      })
      .catch((error: any) => {
        console.log(error.response.data);
      });

    // if (localdata !== null) {
    //   axios
    //     .get(
    //       `https://api.ibupembelajar.id/api-ipi/statuseventregisterbyiduser/${localdata.id}/idevent/${params.id}/`,
    //       options
    //     )
    //     .then((res: any) => {
    //       setStatus(res.data);
    //     })
    //     .catch((error: any) => {
    //       console.log(error.response.data);
    //     });
    // }
  }, []);

  const pilihSertifikat = [
    { value: "Ya", label: "Ya" },
    { value: "Tidak", label: "Tidak" },
  ];

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    setLoading(true);
    const data = new FormData();
    setIsLoading(true);
    // data.append("user_id", localdata.id);
    data.append("name", e.target.name.value);
    data.append("phone", e.target.phone.value);
    data.append("email", e.target.email.value);
    data.append("course_id", id);
    data.append("domicile", e.target.domisili.value);
    if (e.target.jenis_sertifikat) {
      data.append("type_certificate", e.target.jenis_sertifikat.value);
    }
    if (e.target.setifikat) {
      data.append("language_certificate", e.target.setifikat.value);
    }
    if (e.target.score) {
      data.append("score_certificate", e.target.score.value);
    }
    if (levelText) {
      data.append("level_text", levelText);
    }
    if (scheduleText) {
      data.append("schedule_text", scheduleText);
    }
    axios
      .post(ENROLLEDCOURSE_URL, data, options)
      .then(() => {
        axios
          .get(`${HOMECOURSELEVEL_URL}${e.target.level.value}/`)
          .then((res: any) => {
            setLinkWAG(res.data.link_wa);
            setIsLoading(false);
            setIsHideSubmit(true);
          })
          .catch((error: any) => {
            console.log(error.response.data);
            setIsLoading(false);
          });
      })
      .catch((error) => {
        errorHandling(error.response.data);
        setLoading(false);
      });
  };

  // var dateEvent = moment().format("YYYY-MM-DD");
  // var isValidEvent = moment(dateEvent).isSameOrBefore(`${event.date}`);

  return (
    <div className={`nc-SingleSidebar space-y-6`}>
      <br />
      <br />
      {/* <Alert color="primary">
        <h3>Terima kasih atas antusias ibu. Pendaftaran sudah ditutup</h3>
      </Alert> */}
      <div
        className={`nc-PageSubcription space-y-6`}
        data-nc-id="PageSubcription"
      >
        {isAlertOn && (
          <Alert onClick={() => setAlert(false)} color="danger">
            {errorMsg}
          </Alert>
        )}
            <form onSubmit={handleSubmit}>
              <label className="block">
                <Label>Nama (*)</Label>
                <Input name="name" type="text" className="mt-1" />
              </label>
              <label className="block">
                <Label>No. Telp (*)</Label>
                <Input name="phone" type="number" className="mt-1" />
              </label>
              <label className="block">
                <Label>E-mail (*)</Label>
                <Input name="email" type="text" className="mt-1" />
              </label>
              <label className="block">
                <Label>Domisili (*)</Label>
                <Input
                  name="domisili"
                  type="text"
                  className="mt-1"
                  onChange={() => setIsSelectDomisili(true)}
                />
              </label>
              <label className="block">
                <Label>
                  Apakah Anda Punya Sertifikat Bahasa Sebelumnya? (*)
                </Label>
                <Select
                  className="mt-1"
                  options={pilihSertifikat}
                  name="setifikat"
                  onChange={(e: any) => {
                    setIsSelectSertifikat(true);
                    if (e.value === "Ya") {
                      setIsCertificate(true);
                    } else {
                      setIsCertificate(false);
                    }
                  }}
                />
              </label>
              {isCertificate && (
                <>
                  <label className="block">
                    <Label>
                      Jenis Sertifikat (misal: LIA TOEFL - INTERMEDIATE)
                    </Label>
                    <Input
                      name="jenis_sertifikat"
                      type="text"
                      className="mt-1"
                    />
                  </label>
                  <label className="block">
                    <Label>Nilai Test</Label>
                    <Input name="score" type="text" className="mt-1" />
                  </label>
                </>
              )}
              <label className="block">
                <Label>Pilih Level (*)</Label>
                <Select
                  className="mt-1"
                  options={levels}
                  name="level"
                  onChange={(e: any) => {
                    setIsSelectLevel(true);
                    setLevelText(e.label);
                    axios
                      .get(`${HOMECOURSESCHEDULE_URL}${e.value}/`)
                      .then((res: any) => {
                        // console.log("RES", res.data);
                        setSchedules(
                          res.data.map((item: any) => ({
                            value: item.id,
                            label: item.schedule_text,
                          }))
                        );
                      })
                      .catch((error: any) => {
                        console.log(error.response.data);
                      });
                  }}
                />
              </label>
              {/* <label className="block">
                <Label>Pilih Jadwal (*)</Label>
                <Select
                  className="mt-1"
                  options={schedules}
                  name="schedule"
                  onChange={(e: any) => {
                    setScheduleText(e.label);
                    setIsSelectSchedule(true);
                    console.log("e", e.value, e.label);
                  }}
                />
              </label> */}
              {isLoading && <p>Loading..</p>}
              {!isHideSubmit &&
                (isSelectLevel &&
                isSelectDomisili &&
                isSelectSertifikat ? (
                  <ButtonPrimary className="md:col-span-2 mt-8" type="submit">
                    Daftar Sekarang
                  </ButtonPrimary>
                ) : (
                  <ButtonSecondary
                    className="md:col-span-2 mt-8"
                    type="submit"
                    disabled
                  >
                    Daftar Sekarang
                  </ButtonSecondary>
                ))}
              {linkWAG && (
                <>
                  <br />
                  <br />
                  <Alert color="primary">
                    <h3>Proses Daftar berhasil</h3>
                    <br />
                    <p>
                      Silahkan masuk ke dalam grup kelas untuk mendapatkan
                      informasi selanjutnya
                    </p>
                    <br />
                    <a href={linkWAG} target="_blank" rel="noreferrer">
                      <u>{linkWAG}</u>
                    </a>
                  </Alert>
                </>
              )}
            </form>
      </div>
    </div>
  );
};
